import { ProfileHeader } from '../../types';
import { timeAgo } from '../../utils';
import novice from '../../assets/novice.svg';
import contributor from '../../assets/contributor.svg';
import expert from '../../assets/expert.svg';
import master from '../../assets/master.svg';
import grandMaster from '../../assets/grandMaster.svg';
import StyledButton from '../../atoms/common/StyledButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import FollowButton from '../general/FollowButton';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Header = ({
  full_name,
  company,
  link,
  location,
  job_title,
  profile_image,
  is_followed,
  competition_level,
  discussion_level,
  created_at,
  seeing_join_date_privacy,
  last_seen_and_online_privacy,
  highestLevel,
  id,
}: ProfileHeader & { id: number }) => {
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <div className="rounded-2xl lg:border">
      <div className="flex justify-between gap-3 border-b-2 p-2 max-sm:flex-col lg:p-5">
        <div className="flex gap-5">
          <img
            src={profile_image}
            alt={full_name}
            className="h-20 w-20 rounded-full xs:h-40 xs:w-40"
          />
          <div className="text-start">
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-semibold">{full_name}</h2>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <i className="pi pi-linkedin text-light-blue"></i>
              </a>
            </div>
            <p>
              {job_title} . {company}
            </p>
            <p>
              <i className="pi pi-map-marker"></i> {location}
            </p>
            {(seeing_join_date_privacy || full_name === user.full_name) && (
              <p>
                <i className="pi pi-calendar"></i> Joined{' '}
                {timeAgo(new Date(created_at))}
              </p>
            )}
            {id !== user?.id && (
              <div className='mt-3'>

                <FollowButton is_followed={is_followed} id={id} />
              </div>
            )}
          </div>
        </div>

        <div className="flex w-fit items-center justify-center px-3 sm:border-l-2">
          {highestLevel === 'Novice' && (
            <div className="items-center gap-2 max-sm:flex">
              <img src={novice} alt="Novice" className="mx-auto max-sm:w-6" />
              <h3 className="text-center font-semibold text-primary-color">
                Novice
              </h3>
            </div>
          )}
          {highestLevel === 'Contributor' && (
            <div className="items-center gap-2 max-sm:flex">
              <img
                src={contributor}
                alt="Contributor"
                className="mx-auto max-sm:w-6"
              />
              <h3 className="text-center font-semibold text-primary-color">
                Contributor
              </h3>
            </div>
          )}
          {highestLevel === 'Expert' && (
            <div className="items-center gap-2 max-sm:flex">
              <img src={expert} alt="Expert" className="mx-auto max-sm:w-6" />
              <h3 className="text-center font-semibold text-primary-color">
                Expert
              </h3>
            </div>
          )}
          {highestLevel === 'Master' && (
            <div className="items-center gap-2 max-sm:flex">
              <img src={master} alt="Master" className="mx-auto max-sm:w-6" />
              <h3 className="text-center font-semibold text-primary-color">
                Master
              </h3>
            </div>
          )}
          {highestLevel === 'Grandmaster' && (
            <div className="items-center gap-2 max-sm:flex">
              <img
                src={grandMaster}
                alt="Grandmaster"
                className="mx-auto max-sm:w-6"
              />
              <h3 className="text-center font-semibold text-primary-color">
                Grandmaster
              </h3>
            </div>
          )}
        </div>
      </div>
      <Navbar />
    </div>
  );
};

const navOptions = [
  'Home',
  'Comeptitions',
  'Discussions',
  'Followers',
  'Projects',
  'Submissions',
  // 'Notifications',
  // 'Accounts',
];

const Navbar = () => {
  const path = window.location.pathname.split('/').pop();

  const [activeOption, setActiveOption] = useState(
    navOptions.includes(`${path?.[0].toUpperCase()}${path?.slice(1)}` as string)
      ? `${path?.[0].toUpperCase()}${path?.slice(1)}`
      : 'Home'
  );
  return (
    <div className="flex flex-wrap items-center justify-start gap-2 p-2">
      {navOptions.map((option) => (
        <Link
          key={option}
          className={`p-2 ${activeOption === option ? 'font-semibold' : ''}`}
          onClick={() => setActiveOption(option)}
          to={`/${window.location.pathname.split('/')[1] === 'your-work' ? 'your-work' : `profile/${window.location.pathname.split('/')[2]}`}/${option.toLowerCase()}`}
        >
          {option}
        </Link>
      ))}
      <div className="flex-grow"></div>
      {window.location.pathname.split('/')[1] === 'your-work' && (
        <a href="/your-work/edit">
          <StyledButton label="Edit Public Profile" />
        </a>
      )}
    </div>
  );
};

export default Header;
