import { createSlice } from '@reduxjs/toolkit';

const initialUser = localStorage.getItem('bidayyauser')
  ? JSON.parse(localStorage.getItem('bidayyauser') as string)
  : null;

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: initialUser,
  },
  reducers: {
    signUp: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem('bidayyauser', JSON.stringify(action.payload.user));
      window.location.href = '/';
    },
    signIn: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem('bidayyauser', JSON.stringify(action.payload.user));
      if (
        window.location.pathname === '/signin' ||
        window.location.pathname === '/signup' ||
        window.location.pathname === '/forgot-password'
      )
        window.location.href = '/';
    },
    signOut: (state) => {
      state.user = null;
      localStorage.removeItem('bidayyauser');
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem('bidayyauser', JSON.stringify(action.payload.user));
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.removeItem('bidayyauser');
    },
    changeEmail: (state, action) => {
      state.user.email = action.payload.email;
      localStorage.setItem('bidayyauser', JSON.stringify(action.payload.user));
    },
    updateProfile: (state, action) => {
      state.user.full_name = action.payload.user.full_name;
      state.user.job_title = action.payload.user.job_title;
      state.user.company = action.payload.user.company;
      state.user.link = action.payload.user.link;
      state.user.location = action.payload.user.location;
      state.user.profile_image = action.payload.user.profile_image;
      state.user.last_seen_and_online_privacy =
        action.payload.user.last_seen_and_online_privacy;
      state.user.seeing_join_date_privacy =
        action.payload.user.seeing_join_date_privacy;
      localStorage.setItem('bidayyauser', JSON.stringify(action.payload.user));
    },
  },
});

export const {
  signUp,
  signIn,
  signOut,
  setUser,
  clearUser,
  changeEmail,
  updateProfile,
} = userSlice.actions;
export default userSlice.reducer;
