import ImageComponent from '../../atoms/common/ImageComponent';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import { Submission } from '../../types';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import SubmissionDetails from '../../atoms/competition/SubmissionDetails';
import { Link } from 'react-router-dom';

export const formatDateTo12Hour = (d: string): string => {
  const date = new Date(d);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
};

export const isFinished = (d: string) => {
  const date = new Date(d);
  const currentDate = new Date();
  return date < currentDate;
};

const SubmissionCard = ({
  submission,
  deadline,
  selectMedal,
  initialSubmission,
  goldCount,
  silverCount,
  bronzeCount,
  goldLimit,
  silverLimit,
  bronzeLimit,
  selectedWinner,
  setSelectedWinner,
  isWinner,
}: {
  submission: Submission;
  deadline: string;
  selectMedal: (id: number, medalType: string) => void;
  initialSubmission: Submission;
  goldCount: number;
  silverCount: number;
  bronzeCount: number;
  goldLimit: number;
  silverLimit: number;
  bronzeLimit: number;
  selectedWinner: number | null;
  setSelectedWinner: (id: number | null) => void;
  isWinner: boolean;
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <div className="relative flex w-full items-center justify-between overflow-hidden rounded-xl border">
      <div className="flex items-center justify-start gap-3">
        {submission.medal_type === 'GOLD' && !isWinner && (
          <div
            className={`absolute right-0 top-0 ${selectedWinner !== null && selectedWinner !== submission.id ? 'cursor-not-allowed' : 'cursor-pointer'} `}
            onClick={() => {
              if (selectedWinner === null) setSelectedWinner(submission.id);
              else if (selectedWinner === submission.id)
                setSelectedWinner(null);
            }}
          >
            {selectedWinner === submission.id ? (
              <i className="pi pi-star-fill text-5xl text-yellow-500"></i>
            ) : (
              <i className="pi pi-star text-5xl text-yellow-500"></i>
            )}
          </div>
        )}
        {initialSubmission.is_winner && (
          <div className="absolute right-0 top-0 cursor-pointer">
            <i className="pi pi-star-fill text-5xl text-yellow-500"></i>
          </div>
        )}
        <div className="h-fit w-fit overflow-hidden max-sm:hidden">
          <ImageComponent
            src={submission.cover_image}
            imageName="Submission cover image"
            className="!h-40 !min-h-40 !w-40 !min-w-40"
          />
        </div>
        <div className="max-sm:px-2">
          <h2 className="text-2xl font-semibold">{submission.title}</h2>
          <p className="max-w900:flex max-w900:flex-col">
            <span>{`${formatDateTo12Hour(submission.updated_at || submission.created_at)}`}</span>
            <Link
              to={`/profile/${submission.user.id}`}
            >{` by ${submission.user.full_name}`}</Link>
          </p>
          <p className="max-w-[40vw] overflow-hidden text-ellipsis whitespace-nowrap max-lg:hidden">
            {submission.description}
          </p>
          <div
            className="mr-3 flex cursor-pointer items-center justify-start gap-1 text-light-blue"
            onClick={() => setShowDetails(true)}
          >
            <div>More</div>
            <i className="pi pi-arrow-circle-right"></i>
          </div>
        </div>
      </div>

      <div>
        {isFinished(deadline) && !initialSubmission.medal_type && (
          <div className="flex min-w-fit flex-col items-center justify-center gap-2 p-3 xl:border-l">
            <div
              className={`flex min-w-fit ${goldCount >= goldLimit && submission.medal_type !== 'GOLD' ? 'cursor-not-allowed' : 'cursor-pointer'} items-center justify-center rounded-full border-8 p-[1px] ${submission.medal_type === 'GOLD' ? 'border-light-blue' : ''} `}
              onClick={() => selectMedal(submission.id, 'GOLD')}
            >
              <img src={goldMedal} alt="Gold Medal" className="h-9 w-9" />
            </div>
            <div
              className={`flex min-w-fit ${silverCount >= silverLimit && submission.medal_type !== 'SILVER' ? 'cursor-not-allowed' : 'cursor-pointer'} items-center justify-center rounded-full border-8 p-[1px] ${submission.medal_type === 'SILVER' ? 'border-light-blue' : ''} `}
              onClick={() => selectMedal(submission.id, 'SILVER')}
            >
              <img src={silverMedal} alt="Silver Medal" className="h-9 w-9" />
            </div>
            <div
              className={`flex min-w-fit ${bronzeCount >= bronzeLimit && submission.medal_type !== 'BRONZE' ? 'cursor-not-allowed' : 'cursor-pointer'} items-center justify-center rounded-full border-8 p-[1px] ${submission.medal_type === 'BRONZE' ? 'border-light-blue' : ''} `}
              onClick={() => selectMedal(submission.id, 'BRONZE')}
            >
              <img src={bronzeMedal} alt="Bronze Medal" className="h-9 w-9" />
            </div>
          </div>
        )}
        {initialSubmission.medal_type === 'GOLD' && (
          <div className="flex min-w-fit flex-col items-center justify-center gap-2 p-3 xl:border-l">
            <div className="flex min-w-fit items-center justify-center rounded-full border-8 p-[1px]">
              <img src={goldMedal} alt="Gold Medal" className="h-9 w-9" />
            </div>
          </div>
        )}
        {initialSubmission.medal_type === 'SILVER' && (
          <div className="flex min-w-fit flex-col items-center justify-center gap-2 p-3 xl:border-l">
            <div className="flex min-w-fit items-center justify-center rounded-full border-8 p-[1px]">
              <img src={silverMedal} alt="Silver Medal" className="h-9 w-9" />
            </div>
          </div>
        )}
        {initialSubmission.medal_type === 'BRONZE' && (
          <div className="flex min-w-fit flex-col items-center justify-center gap-2 p-3 xl:border-l">
            <div className="flex min-w-fit items-center justify-center rounded-full border-8 p-[1px]">
              <img src={bronzeMedal} alt="Bronze Medal" className="h-9 w-9" />
            </div>
          </div>
        )}
        <Dialog
          visible={showDetails}
          style={{
            width: '80vw',
            maxHeight: '95vh',
          }}
          onHide={() => setShowDetails(false)}
          draggable={false}
        >
          <div className="flex w-full items-center justify-center gap-3">
            <SubmissionDetails submission={submission} fromDashboard />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SubmissionCard;
