import React, { useState } from 'react';
import StyledButton from '../../atoms/common/StyledButton';

const FollowButton = ({
  is_followed,
  id,
}: {
  is_followed: boolean;
  id: number;
}) => {
  const [following, setFollowing] = useState(is_followed);
  const follow = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/toggle-follow/${id}`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        setFollowing(!following);
      } else {
        console.log('Error following user');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <StyledButton
      label={following ? 'Unfollow' : 'Follow'}
      variant={following ? 'danger' : 'primary'}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        follow();
      }}
    />
  );
};

export default FollowButton;
