import summayTitleLogo from '../../assets/summaryTitleLogo.svg';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import novice from '../../assets/novice.svg';
import contributor from '../../assets/contributor.svg';
import expert from '../../assets/expert.svg';
import master from '../../assets/master.svg';
import grandMaster from '../../assets/grandMaster.svg';

const DetailedSummaryCard = ({
  title,
  goldCount,
  silverCount,
  bronzeCount,
  level,
  rank,
  scorePoints,
  highestRank,
  counts,
  totalUsersCount,
}: {
  title: string;
  goldCount: number;
  silverCount: number;
  bronzeCount: number;
  level: string;
  rank: number;
  scorePoints: number;
  highestRank: number;
  counts: {
    count: number;
    title: string;
  }[];
  totalUsersCount: number;
}) => {
  return (
    <div className="overflow-hidden rounded-2xl border">
      <h2 className="bg-gray-100 px-4 py-2 text-start text-lg font-semibold text-gray-500">
        {title} summary
      </h2>
      <div className="flex w-full max-sm:flex-col">
        <div className="order-2 flex flex-col items-center justify-center border-b p-10 max-sm:w-full sm:order-1 sm:w-[25%] sm:border-r">
          {level === 'Novice' && <img src={novice} alt="novice" />}
          {level === 'Contributor' && (
            <img src={contributor} alt="contributor" />
          )}
          {level === 'Expert' && <img src={expert} alt="expert" />}
          {level === 'Master' && <img src={master} alt="master" />}
          {level === 'Grandmaster' && (
            <img src={grandMaster} alt="grandmaster" />
          )}
          <h3>{title}s</h3>
          <h3>{level}</h3>
        </div>
        <div className="order-1 flex flex-col items-center justify-center max-sm:w-full sm:order-2 sm:flex-grow">
          <div className="w-full py-1">
            {rank === -1 && <p className="p-10 text-gray-500">Unranked</p>}
            {rank !== -1 && (
              <div className="flex w-full max-lg:flex-col">
                <div className="w-[50%] self-center lg:border-r">
                  <p className="text-lg font-bold text-primary-color">
                    Current Rank
                  </p>
                  <p className="font-bold">{rank}</p>
                  <p>of {totalUsersCount}</p>
                </div>
                <div className="w-[50%] self-center">
                  <p className="text-lg font-bold text-primary-color">
                    Highest Rank
                  </p>
                  <p className="font-bold">{highestRank}</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full flex-grow items-center bg-gray-100 py-3">
            <div className="flex-grow border-r">
              <img
                src={goldMedal}
                alt="gold medal"
                className="mx-auto w-[30px]"
              />
              <p className="font-bold text-gold-medal">{goldCount}</p>
            </div>
            <div className="flex-grow border-r">
              <img
                src={silverMedal}
                alt="silver medal"
                className="mx-auto w-[30px]"
              />
              <p className="font-bold text-silver-medal">{silverCount}</p>
            </div>
            <div className="flex-grow">
              <img
                src={bronzeMedal}
                alt="bronze medal"
                className="mx-auto w-[30px]"
              />
              <p className="font-bold text-bronze-medal">{bronzeCount}</p>
            </div>
          </div>
        </div>
        <div className="order-3 flex flex-col items-center justify-center border-l p-10 max-sm:w-full sm:order-3 sm:w-[25%] sm:border-l">
          {counts.map((count, index) => (
            <div key={index}>
              <p>{count.title}</p>
              <p className="font-bold">{count.count}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailedSummaryCard;
