import { useEffect, useState } from 'react';
import { CompetitionSummary, DiscussionSummary } from '../../types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';

const ProfileHome = () => {
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.user.user);

  const finalId =
    window.location.pathname.split('/')[1] === 'your-work' ? user.id : id;

  const [competitionSummary, setCompetitionSummary] =
    useState<CompetitionSummary>();

  const [discussionSummary, setDiscussionSummary] =
    useState<DiscussionSummary>();

  const fetchCompetitionSummary = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/competitions-summary`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetitionSummary(data);
      } else {
        console.log('Error fetching competition summary');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDiscussionSummary = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/discussions-summary`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setDiscussionSummary(data);
      } else {
        console.log('Error fetching discussion summary');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompetitionSummary();
    fetchDiscussionSummary();
  }, []);
  return (
    <div className="flex w-full flex-wrap items-center justify-start gap-4">
      {competitionSummary && (
        <SummaryCard
          title="Competition"
          bronzeCount={competitionSummary.competition_bronze_medals}
          silverCount={competitionSummary.competition_silver_medals}
          goldCount={competitionSummary.competition_gold_medals}
          level={competitionSummary.competition_level}
          rank={competitionSummary.competition_rank}
          scorePoints={competitionSummary.competition_score_points}
          highestRank={competitionSummary.highest_competition_rank}
          counts={[
            {
              count: competitionSummary._count.competitionsJoined,
              title: 'Competitions Joined',
            },
            // {
            //   count: competitionSummary._count.competitionsHosted,
            //   title: 'Competitions Hosted',
            // },
          ]}
          totalUsersCount={competitionSummary.totalNumberofUsers}
        />
      )}
      {discussionSummary && (
        <SummaryCard
          title="Discussion"
          bronzeCount={discussionSummary.discussion_bronze_medals}
          silverCount={discussionSummary.discussion_silver_medals}
          goldCount={discussionSummary.discussion_gold_medals}
          level={discussionSummary.discussion_level}
          rank={discussionSummary.discussion_rank}
          scorePoints={discussionSummary.discussion_score_points}
          highestRank={discussionSummary.highest_discussion_rank}
          counts={[
            {
              count: discussionSummary._count.topics,
              title: 'Topics',
            },
            {
              count: discussionSummary._count.comments,
              title: 'Comments',
            },
          ]}
          totalUsersCount={discussionSummary.totalNumberofUsers}
        />
      )}
    </div>
  );
};

const SummaryCard = ({
  title,
  bronzeCount,
  silverCount,
  goldCount,
  level,
  rank,
  scorePoints,
  highestRank,
  counts,
  totalUsersCount,
}: {
  title: string;
  bronzeCount: number;
  silverCount: number;
  goldCount: number;
  level: string;
  rank: number;
  scorePoints: number;
  highestRank: number;
  counts: {
    count: number;
    title: string;
  }[];
  totalUsersCount: number;
}) => {
  return (
    <div className="flex w-[280px] h-[381px] flex-col items-center overflow-hidden rounded-xl border text-start">
      <h2 className="px-3 py-2 font-semibold text-primary-color">{`${title} ${level}`}</h2>
      <hr className="border-1 w-full text-gray-600" />
      <p className="py-4">{`${rank}/${totalUsersCount}`}</p>
      <div className="mb-3 flex w-full items-center justify-center bg-gray-100">
        <div className="flex-grow border-r-2 p-3 text-center">
          <img src={goldMedal} alt="gold medal" className="mx-auto w-[30px]" />
          <p className="text-gold-medal">{goldCount}</p>
        </div>
        <div className="flex-grow border-r-2 p-3 text-center">
          <img
            src={silverMedal}
            alt="silver medal"
            className="mx-auto w-[30px]"
          />
          <p className="text-silver-medal">{silverCount}</p>
        </div>
        <div className="flex-grow p-3 text-center">
          <img
            src={bronzeMedal}
            alt="bronze medal"
            className="mx-auto w-[30px]"
          />
          <p className="text-bronze-medal">{bronzeCount}</p>
        </div>
      </div>
      <div className='flex-grow w-full flex flex-col items-center justify-center gap-1'>

        {counts.map((c) => (
          <div>
            {c.count === 0 && (
              <p className="text-center text-gray-600">No {c.title} yet</p>
            )}
            {c.count > 0 && (
              <div className="flex flex-col items-center justify-center">
                <p className="text-center font-semibold text-primary-color">
                  {c.title}
                </p>
                <p className="text-center">{c.count}</p>
              </div>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default ProfileHome;
