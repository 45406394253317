import React, { useEffect, useState } from 'react';
import ProtectedPage from './ProtectedPage';
import MainLayout from '../components/MainLayout';
import { StatisticsField } from './Home';
import { CountRanking, Tag, UserRank } from '../types';
import ButtonGroup from '../atoms/common/ButtonGroup';
import { timeAgo } from '../utils';
import novice from '../assets/novice.svg';
import contributor from '../assets/contributor.svg';
import expert from '../assets/expert.svg';
import master from '../assets/master.svg';
import grandmaster from '../assets/grandMaster.svg';
import goldMedal from '../assets/goldMedal.svg';
import silverMedal from '../assets/silverMedal.svg';
import bronzeMedal from '../assets/bronzeMedal.svg';
import { Link } from 'react-router-dom';

const UserRanking = () => {
  const [activeTab, setActiveTab] = useState<'Competition' | 'Discussion'>(
    'Competition'
  );
  const [counts, setCounts] = useState<CountRanking[]>();
  const [ranking, setRanking] = useState<UserRank[]>();
  const [rankColumn, setRankColumn] = useState<number[]>([]);
  const [tierColumn, setTierColumn] = useState<string[]>([]);
  const [userColumn, setUserColumn] = useState<string[]>([]);
  const [joinColumn, setJoinColumn] = useState<string[]>([]);
  const [medalColumn, setMedalColumn] = useState<
    {
      gold: number;
      silver: number;
      bronze: number;
    }[]
  >([]);
  const [pointColumn, setPointColumn] = useState<number[]>([]);

  const fetchRanking = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/count-by-${activeTab.toLocaleLowerCase()}-level`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch ranking counts');
      }

      const data = await response.json();
      setCounts(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRanking();
  }, [activeTab]);

  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const fetchTags = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/tags`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTags(
          data.map((tag: Tag) => ({
            tag_id: tag.tag_id,
            tag_name: tag.tag_name,
            selected: false,
          }))
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    setSelectedTags(
      tags.filter((tag) => tag.selected).map((tag) => tag.tag_id)
    );
  }, [tags]);

  const fetchRankingByTags = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${activeTab.toLocaleLowerCase()}-ranking${selectedTags.length > 0 ? `?tags=${selectedTags.join(',')}` : ''}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch ranking counts');
      }

      const data = await response.json();
      console.log(data);
      setRanking(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRankingByTags();
  }, [selectedTags, activeTab]);

  useEffect(() => {
    if (!ranking) return;

    setRankColumn(
      ranking.map((rank) => rank[activeTab.toLocaleLowerCase() + '_rank'])
    );
    setTierColumn(
      ranking.map((rank) => rank[activeTab.toLocaleLowerCase() + '_level'])
    );
    setUserColumn(ranking.map((rank) => rank.full_name));
    setJoinColumn(ranking.map((rank) => rank.created_at));
    setMedalColumn(
      ranking.map((rank) => ({
        gold: rank[activeTab.toLocaleLowerCase() + '_gold_medals'],
        silver: rank[activeTab.toLocaleLowerCase() + '_silver_medals'],
        bronze: rank[activeTab.toLocaleLowerCase() + '_bronze_medals'],
      }))
    );
    setPointColumn(
      ranking.map(
        (rank) => rank[activeTab.toLocaleLowerCase() + '_score_points']
      )
    );
  }, [ranking]);

  const getTierIcon = (tier: string) => {
    switch (tier) {
      case 'Novice':
        return novice;
      case 'Contributor':
        return contributor;
      case 'Expert':
        return expert;
      case 'Master':
        return master;
      case 'Grandmaster':
        return grandmaster;
      default:
        return novice;
    }
  };

  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 lg:mx-8 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            <h1 className="text-left text-4xl font-bold">Ranking</h1>
          </header>
          <div className="mb-10 mt-16 flex gap-4 border-b-2">
            <button
              className="text-xl"
              style={{
                borderBottom:
                  activeTab === 'Competition' ? '2px solid #2563EB' : 'none',
                fontWeight: activeTab === 'Competition' ? '600' : 'normal',
              }}
              onClick={() => setActiveTab('Competition')}
            >
              Competition
            </button>
            <button
              className="text-xl"
              style={{
                borderBottom:
                  activeTab === 'Discussion' ? '2px solid #2563EB' : 'none',
                fontWeight: activeTab === 'Discussion' ? '600' : 'normal',
              }}
              onClick={() => setActiveTab('Discussion')}
            >
              Discussion
            </button>
          </div>

          <div className="mb-16 flex w-full items-center justify-around">
            {counts?.map((count, index) => (
              <StatisticsField
                ranking
                icon="pi-users pi"
                title={count.level.toString()}
                value={count.count.toString()}
                key={index}
              />
            ))}
          </div>

          <ButtonGroup buttons={tags} setButtons={setTags} />
          <table className="mt-12 w-full border">
            <thead className="border bg-gray-200">
              <tr className="font-semibold text-gray-700">
                <td className="w-fit p-2">Rank</td>
                <td className="w-fit p-2">Tier</td>
                <td className="w-auto p-2">User</td>
                <td className="w-fit p-2">Medals</td>
                <td className="w-fit p-2">Points</td>
              </tr>
            </thead>
            <tbody>
              {rankColumn.map((rank, index) => (
                <tr key={index} className="border-b-2">
                  <td className="w-fit rounded-b-lg p-2">{rank}</td>
                  <td className="w-fit p-2">
                    <img
                      src={getTierIcon(tierColumn[index])}
                      alt={tierColumn[index]}
                      className="mx-auto h-6 w-6"
                    />
                  </td>
                  <td className="flex w-auto justify-between p-2">
                    <Link to={`/profile/${ranking?.[index].id}`}>
                      {userColumn[index]}
                    </Link>
                    <span>{timeAgo(new Date(joinColumn[index]))}</span>
                  </td>
                  <td className="w-fit p-2">
                    <div className="flex items-center justify-center gap-3">
                      <span className="flex items-center gap-1">
                        <img
                          src={goldMedal}
                          alt="gold"
                          className="h-[30px] w-[30px]"
                        />
                        {medalColumn[index].gold}
                      </span>
                      <span className="flex items-center gap-1">
                        <img
                          src={silverMedal}
                          alt="silver"
                          className="h-[30px] w-[30px]"
                        />
                        {medalColumn[index].silver}
                      </span>
                      <span className="flex items-center gap-1">
                        <img
                          src={bronzeMedal}
                          alt="bronze"
                          className="h-[30px] w-[30px]"
                        />
                        {medalColumn[index].bronze}
                      </span>
                    </div>
                  </td>
                  <td className="w-fit p-2">{pointColumn[index].toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export default UserRanking;
