import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FeedbackMessage } from '../../types';
import { parseDate } from './CompetitionDetails';

const DashboardFeedback = () => {
  const { id } = useParams<{ id: string }>();

  const [messages, setMessages] = useState<FeedbackMessage[]>([]);

  const fetchFeedbackMessages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/feedbacks`,
        {
          method: 'GET',
          credentials: 'include',

          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setMessages(data);
      } else {
        console.error('Error fetching feedback messages:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching feedback messages', error);
    }
  };

  useEffect(() => {
    console.log('useEffect');
    const intervalId = setInterval(fetchFeedbackMessages, 2000); //samir

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {messages.map((message, index) => (
        <div key={index} className="my-2 border-b text-start">
          <div className="flex justify-start gap-3">
            <div className='h-full flex flex-col justify-start'>

              <div className="h-[50px] w-[50px] rounded-full bg-light-blue"></div>
            </div>
            <div className='flex-grow'>
              <p>{message.contact}</p>
              <p>{parseDate(message.createdAt)}</p>
              <div
                className="prose text-start"
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></div>
            </div>
          </div>

        </div>
      ))}
    </div>
  );
};

export default DashboardFeedback;
