import React, { useState, useEffect } from 'react';
import MainLayout from '../components/MainLayout';
import ActiveCompetitionItem from '../components/competitions/ActiveCompetitionItem';
import CompetitionCard from '../components/competitions/CompetitionCard';
import SearchBar from '../components/header/SearchBar';
import StyledButton from '../atoms/common/StyledButton';
import ButtonGroup from '../atoms/common/ButtonGroup';
import { Link } from 'react-router-dom';
import { Tag, CompetitionDetails } from '../types';
import { timeLeft } from '../utils';
import ProtectedPage from './ProtectedPage';

const Competitions: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [competitions, setCompetitions] = useState<CompetitionDetails[]>([]);
  const [myActiveCompetitions, setMyActiveCompetitions] = useState<
    CompetitionDetails[]
  >([]);

  const fetchActiveCompetitions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/my-ongoing`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setMyActiveCompetitions(data);
      } else {
        console.error('Error fetching competitions:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching competitions:', error);
    }
  };

  useEffect(() => {
    fetchActiveCompetitions();
  }, []);

  const fetchCompetitions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/home${selectedTags.length > 0 ? `?tags=${selectedTags.join(',')}` : ''}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetitions(data);
      } else if (response.status === 404) {
        setCompetitions([]);
      } else {
        console.error('Error fetching competitions:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching competitions:', error);
    }
  };

  const fetchTags = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/tags`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTags(
          data.map((tag: Tag) => ({
            tag_id: tag.tag_id,
            tag_name: tag.tag_name,
            selected: false,
          }))
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    setSelectedTags(
      tags.filter((tag) => tag.selected).map((tag) => tag.tag_id)
    );
  }, [tags]);

  useEffect(() => {
    fetchCompetitions();
  }, [selectedTags]);

  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 lg:mx-8 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            <h1 className="text-left text-4xl font-bold">Competitions</h1>
            <p className="lg:text-md text-left text-xs text-secondary-text md:w-96">
              Grow your drawing skills by competing in our exciting
              competitions. Find help in the{' '}
              <a className="font-semi-bold underline" href="/#">
                documentation
              </a>{' '}
              or learn about{' '}
              <a className="font-semi-bold underline" href="/#">
                Community Competitions
              </a>
              .
            </p>
            <div className="flex items-center space-x-4">
              <Link to="/create-competition">
                <StyledButton
                  type="button"
                  className="bg-blue-gradient-right font-bold"
                  variant="primary"
                  label="Host a competition"
                />
              </Link>
              <Link to={'/your-work'}>
                <StyledButton
                  type="button"
                  className="border border-primary-text font-bold text-primary-text"
                  variant="secondary"
                  label="Your work"
                />
              </Link>
            </div>
          </header>
          <div className="mb-4 ml-auto mr-4 md:flex">
            <SearchBar placeholder="Search Product..." />
          </div>
          <ButtonGroup buttons={tags} setButtons={setTags} />

          {/* Your Active Competitions Section */}
          <section className="my-4">
            <h2 className="mb-2 flex items-center justify-start text-2xl font-bold">
              <i className="pi pi-user mr-4"></i> My active competitions
            </h2>
            {myActiveCompetitions.map((competition) => (
              <ActiveCompetitionItem
                id={competition.id}
                key={competition.id}
                title={competition.name}
                coverImage={competition.cover_image}
                timeLeft={timeLeft(new Date(competition.submission_deadline))}
              />
            ))}
          </section>

          {/* All Active Competitions Section */}
          <section>
            <div className="my-5 flex items-center justify-start gap-4 text-2xl font-bold">
              <i className="pi pi-user" style={{ fontSize: '1.2rem' }}></i>
              <h2 className="">Active competitions</h2>
            </div>
            <div className="mx-auto flex flex-wrap gap-4 justify-start">
              {competitions.map((competition) => (
                <CompetitionCard
                  key={competition.id}
                  id={competition.id}
                  title={competition.name}
                  companyName={competition.company.company_name}
                  participants={0}
                  description={competition.description}
                  price={competition.price}
                  dueInDays={timeLeft(
                    new Date(competition.submission_deadline)
                  )}
                  coverImage={competition.cover_image}
                />
              ))}
            </div>
          </section>
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export default Competitions;
