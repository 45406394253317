import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RunningCompetition, Submission } from '../../types';
import { parseDate } from '../../atoms/dashboard/CompetitionDetails';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import SubmissionCard from '../hostdashboard/SubmissionCard';
import StyledButton from '../../atoms/common/StyledButton';
import { Dialog } from 'primereact/dialog';
import ImageComponent from '../../atoms/common/ImageComponent';

const RunningCompetitionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [competition, setCompetition] = useState<RunningCompetition>();
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [initialSubmissions, setInitialSubmissions] = useState<Submission[]>(
    []
  );
  const [goldCount, setGoldCount] = useState<number>(0);
  const [silverCount, setSilverCount] = useState<number>(0);
  const [bronzeCount, setBronzeCount] = useState<number>(0);
  const [goldLimit, setGoldLimit] = useState<number>(0);
  const [silverLimit, setSilverLimit] = useState<number>(0);
  const [bronzeLimit, setBronzeLimit] = useState<number>(0);
  const [selectedGold, setSelectedGold] = useState<number[]>();
  const [selectedSilver, setSelectedSilver] = useState<number[]>();
  const [selectedBronze, setSelectedBronze] = useState<number[]>();
  const [selectedWinner, setSelectedWinner] = useState<number | null>(null);
  const [isWinner, setIsWinner] = useState<boolean>(true);

  const fetchCompetition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/all-submissions`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetition(data);
        setInitialSubmissions(data.submissions);
        setIsWinner(data.is_winner);
      } else {
        console.error('Error fetching competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching competition:', error);
    }
  };

  useEffect(() => {
    fetchCompetition();
  }, []);

  useEffect(() => {
    if (competition) {
      setSubmissions(competition.submissions);
      setGoldLimit(competition.ranking.gold.available);
      setSilverLimit(competition.ranking.silver.available);
      setBronzeLimit(competition.ranking.bronze.available);
      setGoldCount(competition.ranking.gold.awarded);
    }
  }, [competition]);

  useEffect(() => {
    if (submissions) {
      setGoldCount(
        submissions.filter((submission) => submission.medal_type === 'GOLD')
          .length
      );
      setSilverCount(
        submissions.filter((submission) => submission.medal_type === 'SILVER')
          .length
      );
      setBronzeCount(
        submissions.filter((submission) => submission.medal_type === 'BRONZE')
          .length
      );
    }
  }, [submissions]);

  const selectMedal = (id: number, medalType: string) => {
    if (medalType === 'GOLD') {
      if (selectedGold?.includes(id)) {
        setSelectedGold(selectedGold.filter((i) => i !== id));
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: null }
              : submission
          )
        );
      } else {
        if (goldCount >= goldLimit) return;
        setSelectedGold([...(selectedGold || []), id]);
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: 'GOLD' }
              : submission
          )
        );
      }
    } else if (medalType === 'SILVER') {
      if (selectedSilver?.includes(id)) {
        setSelectedSilver(selectedSilver.filter((i) => i !== id));
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: null }
              : submission
          )
        );
      } else {
        if (silverCount >= silverLimit) return;
        setSelectedSilver([...(selectedSilver || []), id]);
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: 'SILVER' }
              : submission
          )
        );
      }
    } else if (medalType === 'BRONZE') {
      if (selectedBronze?.includes(id)) {
        setSelectedBronze(selectedBronze.filter((i) => i !== id));
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: null }
              : submission
          )
        );
      } else {
        if (bronzeCount >= bronzeLimit) return;
        setSelectedBronze([...(selectedBronze || []), id]);
        setSubmissions(
          submissions.map((submission) =>
            submission.id === id
              ? { ...submission, medal_type: 'BRONZE' }
              : submission
          )
        );
      }
    }
  };

  const confirmGoldRanking = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions/award-gold-medal`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ submissionIds: selectedGold }),
        }
      );
      if (response.ok) {
        setInitialSubmissions(
          initialSubmissions.map((submission) =>
            selectedGold?.includes(submission.id)
              ? { ...submission, medal_type: 'GOLD' }
              : submission
          )
        );
        setSelectedGold([]);
      } else {
        console.error('Error accepting competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error accepting competition:', error);
    }
  };

  const confirmSilverRanking = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions/award-silver-medal`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ submissionIds: selectedSilver }),
        }
      );
      if (response.ok) {
        setInitialSubmissions(
          initialSubmissions.map((submission) =>
            selectedSilver?.includes(submission.id)
              ? { ...submission, medal_type: 'SILVER' }
              : submission
          )
        );
        setSelectedSilver([]);
      } else {
        console.error('Error accepting competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error accepting competition:', error);
    }
  };

  const confirmBronzeRanking = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions/award-bronze-medal`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ submissionIds: selectedBronze }),
        }
      );
      if (response.ok) {
        setInitialSubmissions(
          initialSubmissions.map((submission) =>
            selectedBronze?.includes(submission.id)
              ? { ...submission, medal_type: 'BRONZE' }
              : submission
          )
        );
        setSelectedBronze([]);
      } else {
        console.error('Error accepting competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error accepting competition:', error);
    }
  };

  const markAsWinner = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions/${selectedWinner}/mark-as-winner`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        setInitialSubmissions(
          initialSubmissions.map((submission) =>
            submission.id === selectedWinner
              ? { ...submission, is_winner: true }
              : submission
          )
        );
        setIsWinner(true);
        setSelectedWinner(null);
      } else {
        console.error('Error marking as winner:', response.statusText);
      }
    } catch (error) {
      console.error('Error marking as winner:', error);
    }
  };

  return (
    <div className="pb-3 text-start">
      <h1 className="text-2xl font-bold sm:text-4xl">Submissions</h1>
      <div className="mt-3 flex w-full items-center justify-center rounded-xl border-2 p-1 max-sm:flex-col">
        <div className="w-full border-r-gray-400 p-2 sm:w-1/2 sm:border-r">
          <h2 className="text-xl font-semibold">Submission Information</h2>
          <div className="mt-3 flex items-center justify-start gap-3">
            <div className="flex items-center justify-center gap-3">
              <i className="pi pi-user text-2xl"></i>
              <div>
                <p>Number of submissions</p>
                {competition?.total_submissions}
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              <i className="pi pi-calendar text-2xl"></i>
              <div>
                <p>Due date</p>
                {parseDate(competition?.submission_deadline || '')}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-2 sm:w-1/2">
          <h2 className="text-xl font-semibold">Ranking</h2>
          <div className="flex w-full items-center justify-around">
            <CircularProgressbar
              value={goldCount}
              maxValue={goldLimit}
              text={`${goldCount}/${goldLimit}`}
              className="h-20 w-20 text-xl"
              styles={buildStyles({
                pathColor: '#FFD97B',
                textColor: '#000',
                trailColor: '#ffffff',
              })}
              strokeWidth={15}
            />
            <CircularProgressbar
              value={silverCount}
              maxValue={silverLimit}
              text={`${silverCount}/${silverLimit}`}
              className="h-20 w-20 text-xl text-gold-medal"
              styles={buildStyles({
                pathColor: '#DDDDDD',
                textColor: '#000',
                trailColor: '#ffffff',
              })}
              strokeWidth={15}
            />{' '}
            <CircularProgressbar
              value={bronzeCount}
              maxValue={bronzeLimit}
              text={`${bronzeCount}/${bronzeLimit}`}
              className="h-20 w-20 text-xl text-gold-medal"
              styles={buildStyles({
                pathColor: '#F3B697',
                textColor: '#000',
                trailColor: '#ffffff',
              })}
              strokeWidth={15}
            />
          </div>
        </div>
      </div>
      {competition && competition.winner !== null && (
        <div className="mt-5 flex h-[200px] items-center rounded-xl border-2">
          <ImageComponent
            src={competition.winner.cover_image}
            imageName="Submission cover image"
            className="!h-[200px] !min-h-[200px]"
          />
          <div className="flex flex-grow flex-col items-center justify-start gap-2">
            <h2 className="text-3xl font-bold">The Winner !</h2>
            <h3 className="text-sm">
              Congrats this is the winner of the competition{' '}
            </h3>
            <Link
              to={`/profile/${competition.winner.user.id}`}
              className="font-bold"
            >
              {competition.winner.user.full_name}
            </Link>
            <StyledButton
              label="Explore Submission"
              variant="primary"
              onClick={() => {
                window.open(
                  `/competitions/${competition.winner?.competition_id}/submissions/${competition.winner?.id}`,
                  '_blank'
                );
              }}
            />
          </div>
        </div>
      )}
      <div className="mt-5 flex flex-col gap-4">
        {submissions.map((submission, index) => (
          <SubmissionCard
            key={submission.id}
            submission={submission}
            deadline={competition?.submission_deadline || ' '}
            selectMedal={selectMedal}
            initialSubmission={initialSubmissions[index]}
            goldCount={goldCount}
            silverCount={silverCount}
            bronzeCount={bronzeCount}
            goldLimit={goldLimit}
            silverLimit={silverLimit}
            bronzeLimit={bronzeLimit}
            selectedWinner={selectedWinner}
            setSelectedWinner={setSelectedWinner}
            isWinner={isWinner}
          />
        ))}
      </div>
      <div className="my-3 flex w-full flex-wrap items-center justify-start gap-2">
        <StyledButton
          label="Publish Gold"
          variant="primary"
          onClick={confirmGoldRanking}
          disabled={!selectedGold || selectedGold.length === 0}
        />
        <StyledButton
          label="Publish Silver"
          variant="primary"
          onClick={confirmSilverRanking}
          disabled={!selectedSilver || selectedSilver.length === 0}
        />
        <StyledButton
          label="Publish Bronze"
          variant="primary"
          onClick={confirmBronzeRanking}
          disabled={!selectedBronze || selectedBronze.length === 0}
        />
        <StyledButton
          label="Publish Winner"
          variant="primary"
          onClick={markAsWinner}
          disabled={selectedWinner === null}
        />
      </div>
    </div>
  );
};

export default RunningCompetitionDetails;
