import { useParams } from 'react-router-dom';
import { useState } from 'react';
import SubmissionForm from '../components/submissions/SubmissionForm';
const CreateSubmission = () => {
  const { id } = useParams<{ id: string }>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [is_private, setIsPrivate] = useState<boolean>(false);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('cover_image', coverImage as File);
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('is_private', is_private.toString());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            contentType: 'multipart/form-data',
          },
          body: formData,
        }
      );
      if (response.ok) {
        window.location.href = `/competitions/${id}`;
      } else {
        console.error('Error submitting submission:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting submission:', error);
    }
  };

  return (
    <div className="mt-24 flex flex-col gap-3 transition-all duration-300 lg:w-1/2 lg:gap-4 xl:mx-8">
      <SubmissionForm
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        files={files}
        setFiles={setFiles}
        is_private={is_private}
        setIsPrivate={setIsPrivate}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateSubmission;
