import { useEffect, useState } from 'react';
import { CompetitionSummary, ProfileCompetition } from '../../types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DetailedSummaryCard from './DetailedSummaryCard';
import { timeAgo } from '../../utils';
import { FaLocationArrow } from 'react-icons/fa';
import ImageComponent from '../../atoms/common/ImageComponent';

const ProfileCompetitions = () => {
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.user.user);

  const finalId =
    window.location.pathname.split('/')[1] === 'your-work' ? user.id : id;

  const [tab, setTab] = useState<'active' | 'completed' | 'hosted'>('active');

  const [competitionSummary, setCompetitionSummary] =
    useState<CompetitionSummary>();

  const [competitions, setCompetitions] = useState<ProfileCompetition[]>();

  const fetchCompetitionSummary = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/competitions-summary`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetitionSummary(data);
      } else {
        console.log('Error fetching competition summary');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCompetitions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/${tab}-competitions`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetitions(data);
      } else {
        console.log('Error fetching competitions');
        setCompetitions([]);
      }
    } catch (error) {
      console.error(error);
      setCompetitions([]);
    }
  };

  useEffect(() => {
    fetchCompetitionSummary();
  }, []);

  useEffect(() => {
    fetchCompetitions();
  }, [tab]);

  return (
    <div>
      {competitionSummary && (
        <DetailedSummaryCard
          title="Competition"
          goldCount={competitionSummary?.competition_gold_medals}
          silverCount={competitionSummary?.competition_silver_medals}
          bronzeCount={competitionSummary?.competition_bronze_medals}
          level={competitionSummary?.competition_level}
          rank={competitionSummary?.competition_rank}
          scorePoints={competitionSummary?.competition_score_points}
          highestRank={competitionSummary?.highest_competition_rank}
          counts={[
            {
              count: competitionSummary._count.competitionsJoined,
              title: 'Joined',
            },
            // {
            //   count: competitionSummary._count.competitionsHosted,
            //   title: 'Hosted',
            // },
          ]}
          totalUsersCount={competitionSummary.totalNumberofUsers}
        />
      )}

      <div className="my-5 flex w-full gap-5 border-b">
        <button
          onClick={() => setTab('active')}
          className={`${tab === 'active' ? 'border-b-2 border-black font-bold' : ''
            }`}
        >
          Active
        </button>
        <button
          onClick={() => setTab('completed')}
          className={`${tab === 'completed' ? 'border-b-2 border-black font-bold' : ''
            }`}
        >
          Completed
        </button>
        <button
          onClick={() => setTab('hosted')}
          className={`${tab === 'hosted' ? 'border-b-2 border-black font-bold' : ''
            }`}
        >
          Hosted
        </button>
      </div>
      <div className="flex flex-col gap-3">
        {competitions &&
          competitions.map((competition) => (
            <CompetitionCard competition={competition} key={competition.id} />
          ))}
      </div>
    </div>
  );
};

const CompetitionCard = ({
  competition,
}: {
  competition: ProfileCompetition;
}) => {
  const formatDescription = (description: string) => {
    return description.length > 50
      ? description.slice(0, 50) + '...'
      : description;
  };
  return (
    <div className="flex w-full items-center justify-end gap-5 border-2 rounded-xl pr-3 max-sm:flex-wrap">
      <div className='h-24'>
        <ImageComponent
          src={competition.cover_image}
          imageName={competition.name}
          className="!min-h-0 !min-w-0 sm:h-16 sm:w-16 !md:w-10"
          object='cover'
        />
      </div>

      <div className="flex-grow text-start py-2">
        <h2 className="font-semibold">{competition.name}</h2>
        <p className="text-gray-600">
          {formatDescription(competition.description)}
        </p>
        <p className="text-gray-600">
          {competition.company.company_name} . {competition.totalNumberofUsers}{' '}
          participants. {timeAgo(new Date(competition.created_at))}
        </p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="w-fit text-gray-600">
          {competition.rank}5/{competition.totalNumberofUsers}10
        </p>
        <div className="flex items-center gap-2">
          <img
            src={competition.cover_image}
            alt={competition.name}
            className="h-[30px] w-[30px] rounded-full"
          ></img>
          <a
            className="text-xl text-primary-color"
            href={`/competitions/${competition.id}`}
          >
            <FaLocationArrow />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompetitions;
